import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/Invite"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Partners } from "../components/PartnersNew"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"
import { getPageData } from "../helpers/getPageData"
import { PageData } from "../interfaces/pageProps"
import { QUESTIONS_FOR_INVITE_FRIEND } from "../components/Questions/helpers"
import { HowGetHalva } from "../components/howGetHalva"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const subTitlePartners =
  "Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца срок рассрочки на новые покупки устанавливает партнер:"

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const isMobile = useMediaQuery("(max-width:600px)")

  const installmentWorksTitle = isMobile ? (
    <>
      Как получить
      <br />
      1000 баллов
    </>
  ) : (
    <>Как получить 1000 баллов</>
  )
  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA countDownTime={countDownTime} />
      <Banner orderNum="1" />
      <HowInstallmentWorks headTitle={installmentWorksTitle} variant="inviteFriend" orderNum="2" />
      <FormPKW
        dataLayerName="longPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
        hasSelect
      />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="inviteFriend"
        title="Ваша выгода с Халвой:"
        orderNum="4"
      />
      <NewCalculator variant="inviteFriend" additionalEventInDataLayer orderNum="5" />
      <HowGetHalva orderNum="6" />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="7"
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="8"
        hasSubtitle
        variant="inviteFriendItems"
        bottomBlock={false}
      />
      <BottomCta variant="inviteFriend" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="9" />
      <Questions questionList={QUESTIONS_FOR_INVITE_FRIEND} orderNum="10" />
      <NewFooter ligal={ligal} orderNum="11" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/invitefriend/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
